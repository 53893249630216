<template>
  <b-card header-tag="header" footer-tag="footer">
    <template v-slot:header>
      <h6 class="mb-0">{{ prop_value.command }} from {{ getSafe(() => device.object) }}</h6>
    </template>
    <template v-if="propLoaded == null">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner variant="primary"> </b-spinner>
      </div>
    </template>
    <template v-if="prop_value.value != null">
      <div class="d-flex justify-content-center mb-3">
        <h2>
          <b-badge variant="dark"
            >{{ prop_value.value }} {{ prop_value.unit }}</b-badge
          >
        </h2>
      </div>
      <b-badge v-if="lastTimeMessage != ''">{{ lastTimeMessage }}</b-badge>
    </template>

    <b-alert
      show
      variant="secondary"
      v-if="prop_value.value == null && propLoaded != null"
      >No generated value</b-alert
    >
  </b-card>
</template>

<script>
import { FETCH_DEVICE_PROP } from "@/core/services/store/devices.module";
export default {
  name: "suitch-prop-widget",
  components: {},
  props: {
    device: null,
    prop: null,
  },
  mounted() {
    this.downloadData();
    this.$store.subscribe((mutation, state) => {
      if (mutation.type == "setMessage") {
        // verify if the type of message is for this widget
        if (
          this.device != null &&
          state.realtime.last_message.token == this.device.token &&
          state.realtime.last_message.type == "prop" &&
          Number(state.realtime.last_message.id) ==
            Number(this.prop.substr(this.prop.indexOf(":") + 1))
        ) {
          // call again the action to get the status
          this.downloadData();
          const this_time = new Date();
          this.lastTimeLoaded = this_time.getTime();
        }
      }
    });
    setInterval(() => {
      if (this.lastTimeLoaded == -1) {
        // this.lastTimeMessage = "loading ...";
      } else {
        const this_time1 = new Date();
        const elapsed_seconds = Math.ceil(
          (this_time1 - this.lastTimeLoaded) / 1000
        );
        if (elapsed_seconds < 60) {
          this.lastTimeMessage = String(elapsed_seconds) + " seconds ago";
        } else {
          this.lastTimeMessage =
            String(Math.floor(elapsed_seconds / 60)) + " minutes ago";
        }
      }
    }, 1000);
  },
  methods: {
    getSafe,
    downloadData() {
      if (this.device != null && this.prop != null) {
        this.$store
          .dispatch(FETCH_DEVICE_PROP, {
            device_token: this.device.token,
            prop_id: this.prop.substr(this.prop.indexOf(":") + 1),
          })
          // go to which page after successfully login
          .then((data) => {
            // download devices list
            this.prop_value = data;
            this.propLoaded = data;
          });
      }
    },
  },
  data() {
    return {
      prop_value: {},
      propLoaded: null,
      lastTimeLoaded: -1,
      lastTimeMessage: "",
    };
  },
  watch: {
    device: function (oldval, newval) {
      oldval, newval;
      this.downloadData();
    },
  },
};
function getSafe(fn) {
    try { return fn(); }
    catch (e) {
      // 
    }
}
</script>
