<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="text-muted mt-3 font-weight-bold font">
        In this section you will be able to build your custom layout
      </div>
    </b-alert>

    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Layout builder</h3>
        </div>
        <div class="card-toolbar">
          <b-button v-b-modal.new-layout-modal variant="outline-primary"
            >Create layout</b-button
          >
        </div>
      </div>
      <div class="card-body">
        <div>
          <div class="d-flex justify-content-center mb-3" v-if="showLoading">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </div>
        <v-alert
          border="top"
          colored-border
          type="info"
          elevation="2"
          v-if="layout.length == 0 && !showLoading"
        >
          You have not created a layout yet, create a new layout to add pods
          inside. Pods are cards with information from one or several devices.
        </v-alert>

        <div v-if="layout.length > 0">
          <b-tabs content-class="mt-3" fill>
            <template v-for="(layout_item, layout_index) in layout">
              <b-tab
                :title="layout_item.name"
                v-bind:key="layout_index"
                title-item-class="tab-title"
              >
                <template #title>
                  <span class="float-left">{{ layout_item.name }}</span>
                  <div style="margin-left:30px;">
                    <button
                      type="button"
                      style="margin-top:-10px"
                      @click="promptRemoveLayout(layout_item)"
                    >
                      <span>×</span>
                    </button>
                  </div>
                </template>
                <b-dropdown text="Menu">
                  <b-dropdown-item @click="addWidgetModal(layout_index)"
                    >Add pod</b-dropdown-item
                  >
                  <b-dropdown-item>Reset to previous</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item
                    v-on:click="
                      saveChanges(fakeLayout[layout_index], layout_item)
                    "
                    >Save changes</b-dropdown-item
                  >
                </b-dropdown>
                <grid-layout
                  :layout.sync="fakeLayout[layout_index]"
                  :col-num="12"
                  :row-height="30"
                  :is-draggable="true"
                  :is-resizable="true"
                  :is-mirrored="false"
                  :vertical-compact="true"
                  :margin="[10, 10]"
                  :use-css-transforms="true"
                >
                  <grid-item
                    v-for="item in fakeLayout[layout_index]"
                    :x="item.x"
                    :y="item.y"
                    :w="item.w"
                    :h="item.h"
                    :i="item.i"
                    :key="item.i"
                  >
                    <SuitchProxyWidget
                      :token="item.token"
                      :widget="item.widget"
                    />
                    <span>
                      <b-icon
                        icon="arrow-up-right-circle"
                        variant="info"
                        v-b-tooltip.hover
                        title="All Details"
                        class="details"
                        font-scale="1.1"
                        @click="goToDetails(item)"
                      ></b-icon>
                    </span>
                    <span>
                      <b-icon
                        icon="x-circle"
                        variant="danger"
                        v-b-tooltip.hover
                        title="Remove Widget"
                        class="remove"
                        font-scale="1.1"
                        @click="removeWidget(item, layout_index)"
                      ></b-icon>
                    </span>
                  </grid-item>
                </grid-layout>
              </b-tab>
            </template>
          </b-tabs>
        </div>
      </div>
    </div>

    <b-modal
      ref="new-layout-modal"
      id="new-layout-modal"
      title="Create a new layout"
      :no-close-on-esc="creatingLayout"
      :no-close-on-backdrop="creatingLayout"
      :hide-header-close="creatingLayout"
      @ok="createLayout"
    >
      <b-form-group
        id="fieldset-1"
        description="Layout name"
        label="Give a name to your layout"
        label-for="input-1"
        :invalid-feedback="invalidFeedback"
        :valid-feedback="validFeedback"
        :state="state"
      >
        <b-form-input
          id="input-1"
          v-model="layout_name"
          :state="layoutNameValidation"
          aria-describedby="layout-name-feedback"
          trim
        ></b-form-input>
        <b-form-invalid-feedback id="layout-name-feedback">
          Enter at least 4 letters
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="fieldset-1"
        description="Let us know a description"
        label="Enter a description"
        label-for="input-1"
        :invalid-feedback="invalidFeedback"
        :valid-feedback="validFeedback"
        :state="state"
      >
        <b-form-input
          id="input-1"
          v-model="layout_description"
          :state="layoutDescriptionValidation"
          aria-describedby="layout-description-feedback"
          trim
        ></b-form-input>
        <b-form-invalid-feedback id="layout-description-feedback">
          Enter at least 6 letters
        </b-form-invalid-feedback>
      </b-form-group>

      <template
        #modal-footer="{ cancel }"
        v-if="
          this.layout_name.length <= 4 || this.layout_description.length <= 6
        "
      >
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="cancel()" :disabled="creatingLayout">
          Cancel
        </b-button>
        <b-button size="sm" disabled>
          OK
        </b-button>
        <!-- Button with custom close trigger value -->
      </template>

      <template
        #modal-footer="{ ok, cancel }"
        v-else-if="
          this.layout_name.length > 4 && this.layout_description.length > 6
        "
      >
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="cancel()" :disabled="creatingLayout">
          Cancel
        </b-button>
        <b-button size="sm" variant="primary" @click="ok()">
          <b-spinner
            small
            v-show="creatingLayout"
            :disabled="creatingLayout"
          ></b-spinner>
          OK
        </b-button>
        <!-- Button with custom close trigger value -->
      </template>
    </b-modal>

    <b-modal
      ref="remove-layout-modal"
      title="Delete layout?"
      @ok="confirmDeleteLayout"
    >
      <p class="my-4">
        Are you sure you want to remove <b>{{ selectedLayout.name }}</b> layout?
      </p>
    </b-modal>

    <b-modal
      ref="select-pod-modal"
      title="Pod selection"
      @ok="confirmAddWidget"
      id="add-pod-modal"
      :no-close-on-esc="addPod"
      :no-close-on-backdrop="addPod"
      :hide-header-close="addPod"
    >
      <div role="group">
        <label for="input-live">Device origin:</label>
        <b-form-select
          value-field="token"
          v-model="selectedDeviceOrigin"
          @change="populateWidgets()"
          text-field="object"
          :options="availableDevices"
          :state="selectedDeviceValidation"
          aria-describedby="input-live-help input-live-feedback"
          placeholder="Select a device"
        ></b-form-select>

        <!-- This will only be shown if the preceding input has an invalid state -->
        <b-form-invalid-feedback id="input-live-feedback">
          No device selected
        </b-form-invalid-feedback>

        <!-- This is a form text block (formerly known as help block) -->
        <b-form-text id="input-live-help"
          >This is the device from which you will extract individual
          widgets</b-form-text
        >
      </div>

      <div role="group">
        <label for="input-live"
          ><b-spinner
            small
            v-show="
              selectedDeviceOrigin != null && posibleWidgetChoices.length == 0
            "
          ></b-spinner>
          Available widgets:</label
        >
        <b-form-select
          :state="selectedWidgetValidation"
          :options="posibleWidgetChoices"
          text-field="label"
          v-model="selectedWidget"
          aria-describedby="input-live-help input-live-feedback"
          placeholder="Select a widget to add"
        ></b-form-select>

        <!-- This will only be shown if the preceding input has an invalid state -->
        <b-form-invalid-feedback id="input-live-feedback">
          No widget selected
        </b-form-invalid-feedback>

        <!-- This is a form text block (formerly known as help block) -->
        <b-form-text id="input-live-help"
          >This is the where you select a widget, showing individual information
          of the device</b-form-text
        >
      </div>

      <template
        #modal-footer="{ cancel }"
        v-if="this.selectedDeviceOrigin == null || this.selectedWidget == null"
      >
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="cancel()" :disabled="addPod">
          Cancel
        </b-button>
        <b-button size="sm" disabled>
          OK
        </b-button>
        <!-- Button with custom close trigger value -->
      </template>

      <template #modal-footer="{ ok, cancel }" v-else>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="cancel()" :disabled="addPod">
          Cancel
        </b-button>
        <b-button size="sm" variant="primary" @click="ok()">
          <b-spinner small v-show="addPod" :disabled="addPod"></b-spinner>
          OK
        </b-button>
        <!-- Button with custom close trigger value -->
      </template>
    </b-modal>
  </div>
</template>
<style>
.remove {
  position: absolute;
  right: 6px;
  top: 5px;
  cursor: pointer;
}
.details {
  position: absolute;
  right: 22.5px;
  top: 5px;
  cursor: pointer;
}
.nav-fill > .nav-link,
.nav-fill .nav-item {
  -webkit-box-flex: 0 !important;
  -ms-flex: 0 0 auto !important;
  flex: 0 0 auto !important;
  text-align: center !important;
}
.tab-title {
  -webkit-box-flex: 0 !important;
  -ms-flex: 0 0 auto !important;
  flex: 0 0 auto !important;
  text-align: center !important;
}
</style>
<script>
import {
  FETCH_LAYOUTS,
  SET_LAYOUTS,
  CREATE_LAYOUT,
  DELETE_LAYOUT,
  UPDATE_LAYOUT,
} from "@/core/services/store/layout-builder.module";
import {
  SET_DEVICES,
  GET_DEVICES,
  FETCH_DEVICE_PROPS,
  FETCH_SINGLE_DEVICE
} from "@/core/services/store/devices.module";
import VueGridLayout from "vue-grid-layout";
import SuitchProxyWidget from "@/view/content/widgets/suitch/suitch-proxy-widget.vue";


export default {
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    SuitchProxyWidget,
  },
  data() {
    return {
      layout_name: "",
      layout_description: "",
      showLoading: true,
      creatingLayout: false,
      fakeLayout: [],
      layout: [
        // { x: 0, y: 0, w: 8, h: 17, i: "0" },
        // { x: 2, y: 0, w: 4, h: 9, i: "1" },
        // { x: 4, y: 0, w: 4, h: 9, i: "2" },
        // { x: 6, y: 0, w: 4, h: 9, i: "3" },
        // { x: 8, y: 0, w: 4, h: 9, i: "4" },
      ],
      selectedLayout: {},
      selectedLayoutIndex: -1,
      availableDevices: [],
      lastSelectedDevice: "",
      selectedDeviceOrigin: null,
      selectedWidget: null,
      posibleWidgetChoices: [],
    };
  },
  computed: {
    selectedDeviceValidation() {
      return this.selectedDeviceOrigin != null;
    },
    selectedWidgetValidation() {
      return this.selectedWidget != null;
    },
    layoutNameValidation() {
      return this.layout_name.length > 4 ? true : false;
    },
    layoutDescriptionValidation() {
      return this.layout_description.length > 6 ? true : false;
    },
  },
  mounted() {
    
    this.$refs["remove-layout-modal"]["ok-disabled"] = true;
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === SET_DEVICES) {
        this.availableDevices = [
          {
            label: "Online devices",
            options: state.devices.devices.filter(
              (element) => element.connection_type == 1
            ),
          },
          {
            label: "Offline devices",
            options: state.devices.devices.filter((element) => {
              return (
                element.connection_type == 0 ||
                element.connection_type == null ||
                element.connection_type == undefined
              );
            }),
          },
        ];
      } else if (mutation.type === SET_LAYOUTS) {
        this.fakeLayout = [];
        this.showLoading = false;
        this.$store.getters.getLayouts.forEach((element) => {
          // element["layout_js"] = [];
          if (
            element.content == null ||
            element.content == undefined ||
            element.content == ""
          ) {
            this.fakeLayout.push([]);
          } else {
            this.fakeLayout.push(JSON.parse(element.content));
          }
        });
        this.layout = this.$store.getters.getLayouts;
      }
    });

    // subscribe to the layouts
    this.$store.dispatch(FETCH_LAYOUTS);
    this.$store.dispatch(GET_DEVICES);
  },
  methods: {
    addWidgetModal(layout_index) {
      this.selectedLayoutIndex = layout_index;
      this.$refs["select-pod-modal"].show();
    },
    removeWidget(item, layout_index) {
      this.fakeLayout[layout_index].splice(
        this.fakeLayout[layout_index].findIndex((element) => {
          return element["i"] === item["i"];
        }),
        1
      );
    },
    goToDetails(token) {
      // problem, we didnt have the version, we need
      //to pull it from the cloud
      
      this.$store
        .dispatch(FETCH_SINGLE_DEVICE, {device_token:token.token})
        // go to which page after successfully login
        .then((data) => {
          console.log("fetching device data", data);
          // download devices list
          data;
          switch (data.version) {
            case null:
              this.$router.push({
                name: "device-generic",
                params: {
                  token: data.token,
                },
              });
              break;
            case "camv1":
              this.$router.push({
                name: "device-camera",
                params: {
                  token: data.token,
                },
              });
              break;
            case "sfir":
              this.$router.push({
                name: "device-sfir",
                params: {
                  token: data.token,
                },
              });
              break;
            case "thermostatv1":
              this.$router.push({
                name: "device-thermo",
                params: {
                  token: data.token,
                },
              });
              break;
              case "trackerv1":
              this.$router.push({
                name: "device-tracker",
                params: {
                  token: data.token,
                },
              });
              break;
              case "seismov1":
              this.$router.push({
                name: "device-seismograph",
                params: {
                  token: data.token,
                },
              });
              break;
          }
        });
    },
    confirmAddWidget() {
      this.fakeLayout[this.selectedLayoutIndex].push({
        x: 0,
        y: 0,
        w: 5,
        h: 5,
        i: String(Math.floor(Math.random() * 10000)),
        widget: this.selectedWidget,
        token: this.selectedDeviceOrigin,
      }).then;
      {
        this.selectedDeviceOrigin = null;
        this.selectedWidget = null;
      }
      // console.log(this.fakeLayout);
    },
    populateWidgets() {
      this.posibleWidgetChoices = [];
      this.selectedWidget = null;

      let device = this.availableDevices[0].options
        .filter((element) => element.token == this.selectedDeviceOrigin)
        .pop();
      if (device == null) {
        device = this.availableDevices[1].options
          .filter((element) => element.token == this.selectedDeviceOrigin)
          .pop();
      }
      // we will need to get all the props
      // verify version of device
      if (device.version == null || device.version == undefined || device.version=="suitchv3" || device.version=="bulbv1" || device.version=="seismov1" || device.version=="sfir" || device.version=="sfir_alpha0" || device.version=="trackerv1" || device.version=="stonev1" || device.version=="enermonv1") {
        this.$store
          .dispatch(FETCH_DEVICE_PROPS, {
            device_token: this.selectedDeviceOrigin,
          })
          // go to which page after successfully login
          .then((data) => {
            // download devices list
            // when null
            this.posibleWidgetChoices.push({
              label: "Status",
              value: "status",
            });
            this.posibleWidgetChoices.push({
              label: "Activity log",
              value: "log",
            });
            this.posibleWidgetChoices.push({
              label: "Actions",
              value: "actions",
            });
            this.posibleWidgetChoices.push({
              label: "Summary of all props (last values)",
              value: "props",
            });

            data.forEach((element) => {
              this.posibleWidgetChoices.push({
                label: "Prop " + element.command + " (last value)",
                value: "prop:" + element.id,
              });
              this.posibleWidgetChoices.push({
                label: "Prop " + element.command + " (line chart)",
                value: "line-chart:" + element.id,
              });
              this.posibleWidgetChoices.push({
                label: "Prop " + element.command + " (bar chart)",
                value: "bar-chart:" + element.id,
              });
            });
          });
        // we can expose many widgets
      } else if (device.version == "camv1") {
        this.posibleWidgetChoices.push({
          label: "Status",
          value: "status",
        });
        this.posibleWidgetChoices.push({
          label: "Activity log",
          value: "log",
        });
        this.posibleWidgetChoices.push({
          label: "Actions",
          value: "actions",
        });
        this.posibleWidgetChoices.push({
          label: "Video feed",
          value: "camera",
        });
      } else if (device.version == "thermostatv1") {
        this.$store
          .dispatch(FETCH_DEVICE_PROPS, {
            device_token: this.selectedDeviceOrigin,
          })
          // go to which page after successfully login
          .then((data) => {
            // download devices list
            // when null
            this.posibleWidgetChoices.push({
              label: "Status",
              value: "status",
            });
            this.posibleWidgetChoices.push({
              label: "Activity log",
              value: "log",
            });
            this.posibleWidgetChoices.push({
              label: "Temperature interface",
              value: "temperature",
            });
            this.posibleWidgetChoices.push({
              label: "Summary of all props (last values)",
              value: "props",
            });

            data.forEach((element) => {
              this.posibleWidgetChoices.push({
                label: "Prop " + element.command + " (last value)",
                value: "prop:" + element.id,
              });
              if(element.command_data!="mode"){
                this.posibleWidgetChoices.push({
                  label: "Prop " + element.command + " (line chart)",
                  value: "line-chart:" + element.id,
                });
                this.posibleWidgetChoices.push({
                  label: "Prop " + element.command + " (bar chart)",
                  value: "bar-chart:" + element.id,
                });
              }
            });
          });
      }
    },
    layoutStringToJS(content) {
      content;
      return [];
    },
    showLayout: function() {
      console.log(this.layout);
    },
    promptRemoveLayout(layoutId) {
      this.selectedLayout = layoutId;
      this.$refs["remove-layout-modal"].show();
      console.log(layoutId);
    },
    confirmDeleteLayout() {
      this.$store
        .dispatch(DELETE_LAYOUT, this.selectedLayout)
        .then((data) => {
          data;
          this.$refs["remove-layout-modal"].hide();
          this.creatingLayout = false;
          this.layout_name = "";
          this.layout_description = "";
          this.$bvToast.toast("The layout has been removed", {
            title: `Result`,
            variant: "success",
            solid: true,
          });
          this.$store.dispatch(FETCH_LAYOUTS);
        })
        .catch((response) => {
          response;
          this.creatingLayout = false;
        });
    },
    saveChanges(layout, item_layout) {
      this.$store
        .dispatch(UPDATE_LAYOUT, {
          layout: JSON.stringify(layout),
          id: item_layout.id,
        })
        .then((data) => {
          data;
          this.creatingLayout = false;
          this.layout_name = "";
          this.layout_description = "";
          this.$bvToast.toast("The layout has been updated", {
            title: `Result`,
            variant: "success",
            solid: true,
          });
        })
        .catch((response) => {
          response;
          this.creatingLayout = false;
        });
    },
    createLayout(bvModalEvt) {
      this.creatingLayout = true;
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // we force update to allow the buttons display the spinner
      // and also to disable the buttons
      this.$forceUpdate();
      this.$store
        .dispatch(CREATE_LAYOUT, {
          name: this.layout_name,
          description: this.layout_description,
        })
        .then((data) => {
          data;
          this.creatingLayout = false;
          this.layout_name = "";
          this.layout_description = "";
          this.$bvToast.toast("The layout has been created", {
            title: `Result`,
            variant: "success",
            solid: true,
          });
          this.$store.dispatch(FETCH_LAYOUTS);
          this.$refs["new-layout-modal"].hide();
        })
        .catch((response) => {
          response;
          this.creatingLayout = false;
        });
    },
  },
};
</script>
