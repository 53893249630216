<template>
  <div>
    <SuitchStatusWidget :device="mdevice" v-if="widget == 'status'" :showName="true"/>
    <SuitchPropsWidget :device="mdevice" v-if="widget == 'props'" :showName="true"/>
    <SuitchActionsWidget :device="mdevice" v-if="widget == 'actions'" :showName="true"/>
    <SuitchLogWidget :device="mdevice" v-if="widget == 'log'" :showName="true"/>
    <SuitchPropWidget :device="mdevice" :prop="widget" v-if="widget.indexOf('prop:')!=-1"/>
    <SuitchImageWidget :device="mdevice" v-if="widget == 'camera'"/>
    <SuitchChartWidget :device="mdevice" v-if="widget.indexOf('-chart:')!=-1" :showName="true" :prop="widget"/>
    <SuitchNestWidget  :device="mdevice" v-if="widget == 'temperature'"/>
  </div>
</template>

<script>
import SuitchStatusWidget from "@/view/content/widgets/suitch/suitch-status-widget.vue";
import SuitchPropsWidget from "@/view/content/widgets/suitch/suitch-props-widget.vue";
import SuitchLogWidget from "@/view/content/widgets/suitch/suitch-log-widget.vue";
import SuitchActionsWidget from "@/view/content/widgets/suitch/suitch-actions-widget.vue";
import SuitchPropWidget from "@/view/content/widgets/suitch/suitch-prop-widget.vue";
import SuitchImageWidget from "@/view/content/widgets/suitch/suitch-image-widget.vue";
import SuitchChartWidget from "@/view/content/widgets/suitch/suitch-chart-widget.vue";
import SuitchNestWidget from "@/view/content/widgets/suitch/suitch-nest-widget.vue";
import { FETCH_SINGLE_DEVICE } from "@/core/services/store/devices.module";
export default {
  name: "suitch-proxy-widget",
  components: {
    SuitchStatusWidget,
    SuitchPropsWidget,
    SuitchLogWidget,
    SuitchActionsWidget,
    SuitchPropWidget,
    SuitchImageWidget,
    SuitchChartWidget,
    SuitchNestWidget,
  },
  props: {
    token: null,
    widget: null,
  },
  mounted() {
    if (this.token != null && this.widget != null) {
      this.$store
        .dispatch(FETCH_SINGLE_DEVICE, { device_token: this.token })
        // go to which page after successfully login
        .then((data) => {
          // download devices list
          this.mdevice = data;
        });
    }
  },
  data() {
    return {
      mdevice: null,
    };
  },
  computed: {},
};
</script>
